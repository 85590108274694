/* Reset básico CSS */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
/* Remove margens e preenchimentos padrão */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Define a fonte padrão */
body {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
}

/* Remove a decoração padrão de links */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove a decoração padrão de listas */
ul,
ol {
  list-style: none;
}

/* Remove bordas e espaçamento de tabelas */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove o espaçamento em elementos de bloco */
blockquote,
pre {
  margin: 0;
  padding: 0;
}

/* Define a altura e a largura mínima padrão */
html,
body {
  height: 100%;
  width: 100%;
}

/* Remove o espaçamento padrão em campos de formulário */
input,
textarea,
button,
select {
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

/* Remove a margem padrão dos campos de formulário */
button {
  cursor: pointer;
}

/* Define o estilo padrão dos botões */
button,
input[type="button"],
input[type="submit"] {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

/* Define o estilo padrão das imagens */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Define a altura padrão das fontes */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

/* Define a altura padrão dos elementos de formulário */
input,
textarea,
select {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.2;
}
