.formCall {
  background-color: #ffffff;
  text-align: center;
  padding: 60px 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formCall h2 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #333333;
}

.formCall h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333333;
}

.formCall p {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #555555;
}

.formCall button {
  padding: 15px 30px;
  background-color: #ff1100;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.1em;
  transition: background-color 0.3s, transform 0.3s;
}

.formCall button:hover {
  background-color: #c40e01;
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .formCall {
    padding: 40px 15px;
  }

  .formCall h2 {
    font-size: 2.5em;
  }

  .formCall h3 {
    font-size: 1.5em;
  }

  .formCall p {
    font-size: 1.1em;
  }

  .formCall button {
    padding: 12px 25px;
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .formCall {
    padding: 30px 10px;
  }

  .formCall h2 {
    font-size: 2em;
  }

  .formCall h3 {
    font-size: 1.5em;
  }

  .formCall p {
    font-size: 1em;
  }

  .formCall button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}
