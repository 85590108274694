.productized-software {
  background-color: #f9f9f9;
  padding: 40px 20px;
  margin-top: 20px;
  border-radius: 8px;
}

.productized-software h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.productized-software p {
  font-size: 1.2em;
  color: #555555;
  margin-bottom: 20px;
  text-align: center;
}

.software-item {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.software-item h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: black;
}

.software-item p {
  font-size: 1.1em;
  color: #555555;
}

.software-item ul {
  list-style-type: none;
  padding: 0;
}

.software-item li {
  font-size: 1em;
  color: #333333;
  padding: 5px 0;
}

.demo-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.1em;
  color: #ffffff;
  background-color: #ff1100;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
}

.demo-link:hover {
  background-color: #c40e01;
}

@media (max-width: 1024px) {
  .productized-software {
    padding: 30px 15px;
  }

  .productized-software h2 {
    font-size: 2em;
  }

  .productized-software p {
    font-size: 1em;
  }

  .software-item h3 {
    font-size: 1.5em;
  }

  .software-item p {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .productized-software {
    padding: 20px 10px;
  }

  .productized-software h2 {
    font-size: 1.8em;
  }

  .productized-software p {
    font-size: 0.9em;
  }

  .software-item h3 {
    font-size: 1.3em;
  }

  .software-item p {
    font-size: 0.9em;
  }
}
