.footer {
  background-color: #333333;
  color: #ffffff;
  padding: 40px 20px;
  border-top: 4px solid #ff1100;
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.footer-section {
  flex: 1;
  margin: 0 20px;
}

.footer-section h4 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #ffffff;
}

.footer-section p,
.footer-section ul {
  font-size: 1em;
  color: #cccccc;
  margin: 0;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #007bff;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 0.9em;
  color: #cccccc;
}

@media (max-width: 1024px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 0 10px;
    text-align: center;
  }

  .footer-section h4 {
    font-size: 1.3em;
  }

  .footer-section p,
  .footer-section ul {
    font-size: 0.9em;
  }

  .footer-bottom p {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 0 5px;
    text-align: center;
  }

  .footer-section h4 {
    font-size: 1.2em;
  }

  .footer-section p,
  .footer-section ul {
    font-size: 0.8em;
  }

  .footer-section ul li {
    margin-bottom: 8px;
  }

  .footer-bottom p {
    font-size: 0.7em;
  }
}
