@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

.header {
  background-color: #ffffff;
  border-bottom: 2px solid #ff1100;
  padding: 10px 20px;
  position: relative;
}

.header .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header .logo {
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 2px;
}

.wrapper .txt-dynamic-nav span {
  font-size: 20px;
  font-weight: 500;
  color: #ff5252;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes erase {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

@keyframes blink-caret {
  from, to { 
    border-color: transparent;
  }
  50% { 
    border-color: #ff5252;
  }
}

.wrapper .txt-dynamic-nav span::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  background-color: #ff5252;
  animation: blink-caret 0.75s step-end infinite;
}








.nav {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.nav.open {
  display: flex;
  max-height: 500px;
}

.nav ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.nav ul li {
  margin: 10px 0;
}

.nav ul li a {
  color: #333333;
  text-decoration: none;
  font-size: 1em;
}

.nav ul li a:hover {
  color: #ff1100;
}

.menu-toggle {
  display: block;
  background: none;
  border: none;
  font-size: 2em;
  color: #333333;
  cursor: pointer;
  z-index: 11;
}

@media (min-width: 769px) {
  .header .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header .header-content {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .header .logo a {
    font-size: 1.6em;
  }

  .nav {
    display: flex;
    flex-direction: row;
    position: static;
    background: none;
    border: none;
    box-shadow: none;
    width: auto;
    max-width: none;
    overflow: visible;
    transition: none;
  }

  .nav ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

  .nav ul li {
    margin-left: 20px;
  }

  .nav ul li a {
    font-size: 1em;
  }

  .menu-toggle {
    display: none;
  }
}
