.form {
  background-color: #ffffff;
  text-align: center;
  padding: 60px 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form h2 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #333333;
}

p {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #555555;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
}
button {
  background-color: #ff1100;
  color: black;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #c40e01;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .form {
    padding: 40px 15px;
  }

  .form h2 {
    font-size: 2.5em;
  }

  p {
    font-size: 1.1em;
  }
}
@media (max-width: 768px) {
  .form {
    padding: 30px 10px;
  }

  .form h2 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.modal input {
  width: 100%;
  margin: 10px 0;
  padding: 8px;
  box-sizing: border-box;
}

.modal button {
  margin: 10px;
  padding: 8px 16px;
}
